<template>
    <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
          (event) =>
            $store.commit({
              type: 'updateSidebarVisible',
              value: event,
            })
        ">
        <CSidebarBrand>
            <svgIcon :name="'bi'" customClass="sidebar-brand-full"/>
            <svgIcon :name="'sygnet'" customClass="sidebar-brand-narrow"/>
        </CSidebarBrand>
        <AppSidebarNav />
        <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" />
    </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    setup() {
        const store = useStore()
        return {
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
        }
    },
}
</script>
