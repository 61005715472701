<template v-if="Object.keys(this.$options.components).find((t) => t === this.name)">
  <component :is="$options.components[name]" :name="name" :color="color" :strokeWidth="strokeWidth" :sizeType="sizeType" :customStyle="customStyle" :secondColor="secondColor" :customClass="customClass"/>
</template>
<script lang="js">
import bi from './bi'
import sygnet from './sygnet'

export default {
  name: "svgIcon",
  components: {
    bi,sygnet
  },
  props: ['name','color','strokeWidth','sizeType','customStyle','secondColor','customClass'],
  watch: {
      defaultIndex() {
          this.name = this.name;
          this.color = this.color;
          this.secondColor = this.secondColor;
          this.strokeWidth = this.strokeWidth;
          this.sizeType = this.sizeType;
          this.customStyle = this.customStyle;
          this.customClass = this.customClass;
      },
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},

};
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
