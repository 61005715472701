<template>
  <svg
    :class="['ic_' + name, customClass]"
    :style="customStyle"
    width="127" height="25" viewBox="0 0 127 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27 13.1089V0.211124H28.689V11.6694H34.3702V13.1089H27Z" :fill="color"/>
    <path d="M36.5028 13.1089V0.211124H40.495C43.5083 0.211124 44.6983 1.43948 44.6983 3.47395C44.6983 4.75989 44.1417 5.75793 42.5871 6.25695V6.33373C44.1993 6.64082 45.2357 7.58128 45.2357 9.44301C45.2357 11.5734 43.777 13.1089 40.8213 13.1089H36.5028ZM38.1918 11.6886H40.7253C42.5295 11.6886 43.4699 10.9209 43.4699 9.36624C43.4699 7.73482 42.4335 7.12064 40.6102 7.12064H38.1918V11.6886ZM38.1918 5.75793H40.2071C41.7617 5.75793 42.9325 5.2973 42.9325 3.70427C42.9325 2.11124 42.0305 1.6698 40.3607 1.6698H38.1918V5.75793Z" :fill="color"/>
    <path d="M50.4193 13.3392C48.7879 13.3392 47.5788 12.9745 46.7151 12.4947V11.0552C47.7131 11.5734 48.9607 11.8997 50.3234 11.8997C52.2043 11.8997 53.0296 11.036 53.0296 9.67333C53.0296 8.46416 52.4922 7.94595 50.9951 7.42773L49.6516 6.98629C47.9242 6.4105 46.8686 5.47004 46.8686 3.47395C46.8686 1.43948 48.2505 0 50.9567 0C52.5114 0 53.3943 0.345476 54.1044 0.710145V2.13043C53.3367 1.78496 52.3003 1.43948 50.8992 1.43948C49.4213 1.43948 48.5768 2.13044 48.5768 3.32041C48.5768 4.49119 48.9607 5.0094 50.3426 5.47004L51.6669 5.91148C53.567 6.52566 54.757 7.38935 54.757 9.53898C54.757 11.823 53.4327 13.3392 50.4193 13.3392Z" :fill="color"/>
    <path d="M64.2544 13.1089L61.0108 0.211124H62.719L65.1565 10.4411H65.2141L68.1314 0.211124H69.5133L72.3923 10.4411H72.4499L74.8682 0.211124H76.4037L73.1984 13.1089H71.567L68.8032 3.0709H68.7456L65.8666 13.1089H64.2544Z" :fill="color"/>
    <path d="M76.5824 13.1089L81.2847 0.211124H82.9161L87.5801 13.1089H85.8143L84.5092 9.4814H79.4806L78.1946 13.1089H76.5824ZM81.9373 2.47591L79.9796 8.04191H83.9909L81.9949 2.47591H81.9373Z" :fill="color"/>
    <path d="M89.3963 13.1089V0.211124H91.0853V11.6694H96.7665V13.1089H89.3963Z" :fill="color"/>
    <path d="M98.8992 13.1089V0.211124H100.588V11.6694H106.269V13.1089H98.8992Z" :fill="color"/>
    <path d="M108.402 13.1089V0.211124H116.079V1.6698H110.091V5.73874H114.717V7.15903H110.091V11.6694H116.137V13.1089H108.402Z" :fill="color"/>
    <path d="M121.511 13.1089V1.6698H117.691V0.211124H127V1.6698H123.181V13.1089H121.511Z" :fill="color"/>
    <path d="M27 23.4975V18.0887H27.6166V22.9574H29.952V23.4975H27Z" :fill="color"/>
    <path d="M33.9147 23.57C33.167 23.57 32.7431 23.1751 32.7431 22.4012V19.4993H33.3366V22.3126C33.3366 22.8284 33.5139 23.038 34.0765 23.038C34.485 23.038 34.8242 22.8849 35.1402 22.7237V19.4993H35.7259V23.4975H35.2172L35.1633 23.1348H35.1325C34.7856 23.3846 34.3771 23.57 33.9147 23.57Z" :fill="color"/>
    <path d="M38.443 23.4975L39.7456 21.4662L38.5047 19.4993H39.1984L40.1541 21.0228L41.025 19.4993H41.6648L40.4778 21.4662L41.7572 23.4975H41.0559L40.0847 21.9015L39.0904 23.4975H38.443Z" :fill="color"/>
    <path d="M45.625 23.57C44.8773 23.57 44.4534 23.1751 44.4534 22.4012V19.4993H45.0469V22.3126C45.0469 22.8284 45.2242 23.038 45.7868 23.038C46.1953 23.038 46.5345 22.8849 46.8505 22.7237V19.4993H47.4362V23.4975H46.9275L46.8736 23.1348H46.8428C46.4959 23.3846 46.0874 23.57 45.625 23.57Z" :fill="color"/>
    <path d="M50.5387 23.4975V19.4993H51.1014V19.9507H51.1399C51.3557 19.6928 51.7719 19.459 52.2035 19.459C52.2883 19.459 52.3962 19.4671 52.4348 19.4751V20.0313C52.3346 20.0152 52.2189 20.0072 52.1573 20.0072C51.7719 20.0072 51.4019 20.1522 51.1322 20.3538V23.4975H50.5387Z" :fill="color"/>
    <path d="M55.4712 24.6986C55.2939 24.6986 55.1012 24.6421 54.9933 24.5857V24.1262C55.0858 24.1424 55.2245 24.1827 55.3632 24.1827C55.7332 24.1827 55.9644 24.1182 56.1263 23.6829L56.2419 23.3524L54.7775 19.4993H55.4095L56.5348 22.5383H56.5502L57.583 19.4993H58.1765L56.6735 23.7474C56.4192 24.4567 56.1032 24.6986 55.4712 24.6986Z" :fill="color"/>
    <path d="M64.9716 23.4975V18.0887H66.5516C67.7463 18.0887 68.2241 18.5884 68.2241 19.451C68.2241 20.0152 67.9698 20.4424 67.3609 20.6278V20.6681C67.9929 20.7729 68.4554 21.1518 68.4554 21.974C68.4554 22.8688 67.8696 23.4975 66.7058 23.4975H64.9716ZM65.5805 22.9655H66.6672C67.4149 22.9655 67.8079 22.6269 67.8079 21.9337C67.8079 21.2324 67.3763 20.9583 66.6056 20.9583H65.5805V22.9655ZM65.5805 20.4505H66.4668C67.1143 20.4505 67.5998 20.2248 67.5998 19.5235C67.5998 18.8464 67.2299 18.6287 66.5054 18.6287H65.5805V20.4505Z" :fill="color"/>
    <path d="M72.8501 23.57C71.771 23.57 71.1236 22.8446 71.1236 21.571V21.45C71.1236 20.1845 71.825 19.4268 72.8578 19.4268C73.9369 19.4268 74.592 20.1603 74.592 21.4259V21.5387C74.592 22.8123 73.8829 23.57 72.8501 23.57ZM72.8655 23.0622C73.5592 23.0622 73.9908 22.5544 73.9908 21.6113V21.4097C73.9908 20.2973 73.4744 19.9346 72.8501 19.9346C72.1564 19.9346 71.7248 20.4505 71.7248 21.3856V21.5871C71.7248 22.6995 72.2258 23.0622 72.8655 23.0622Z" :fill="color"/>
    <path d="M78.9424 23.57C77.8633 23.57 77.2159 22.8446 77.2159 21.571V21.45C77.2159 20.1845 77.9173 19.4268 78.9501 19.4268C80.0291 19.4268 80.6843 20.1603 80.6843 21.4259V21.5387C80.6843 22.8123 79.9752 23.57 78.9424 23.57ZM78.9578 23.0622C79.6514 23.0622 80.0831 22.5544 80.0831 21.6113V21.4097C80.0831 20.2973 79.5667 19.9346 78.9424 19.9346C78.2487 19.9346 77.8171 20.4505 77.8171 21.3856V21.5871C77.8171 22.6995 78.318 23.0622 78.9578 23.0622Z" :fill="color"/>
    <path d="M83.5471 23.4975V19.4993H84.1097V19.8459H84.1405C84.4951 19.6122 84.9036 19.4268 85.3352 19.4268C85.7668 19.4268 86.0751 19.5719 86.2524 19.8701H86.2832C86.6994 19.588 87.1465 19.4268 87.5781 19.4268C88.2409 19.4268 88.6263 19.7411 88.6263 20.515V23.4975H88.0482V20.644C88.0482 20.1603 87.8633 19.9588 87.3469 19.9588C87.0463 19.9588 86.7226 20.0797 86.3603 20.249C86.3757 20.3457 86.3834 20.4183 86.3834 20.515V23.4975H85.7977V20.644C85.7977 20.1603 85.6127 19.9588 85.104 19.9588C84.7803 19.9588 84.4411 20.0958 84.1328 20.249V23.4975H83.5471Z" :fill="color"/>
    <path d="M96.9191 23.5942C96.287 23.5942 95.7938 23.433 95.4469 23.2315V22.6914C95.84 22.901 96.3487 23.0461 96.8959 23.0461C97.6898 23.0461 98.0366 22.6592 98.0366 22.0707C98.0366 21.5145 97.7823 21.2888 97.158 21.0792L96.657 20.8938C95.9325 20.644 95.524 20.257 95.524 19.4348C95.524 18.5965 96.0558 18 97.1349 18C97.7438 18 98.106 18.1532 98.3912 18.2983V18.8383C98.0906 18.6932 97.6821 18.5401 97.1117 18.5401C96.4797 18.5401 96.1252 18.8625 96.1252 19.3784C96.1252 19.8943 96.3102 20.12 96.8959 20.3296L97.4046 20.4989C98.1677 20.7729 98.6532 21.1115 98.6532 22.0224C98.6532 22.9655 98.1291 23.5942 96.9191 23.5942Z" :fill="color"/>
    <path d="M104.05 24.6905V23.2396H104.019C103.818 23.3846 103.472 23.57 102.932 23.57C102.169 23.57 101.344 23.0864 101.344 21.6113V21.5065C101.344 19.9265 102.2 19.4268 103.217 19.4268C103.857 19.4268 104.327 19.5557 104.643 19.717V24.6905H104.05ZM103.109 23.0461C103.556 23.0461 103.872 22.8849 104.05 22.7237V20.0716C103.842 19.991 103.541 19.9265 103.179 19.9265C102.454 19.9265 101.961 20.3457 101.961 21.4259V21.5871C101.961 22.7075 102.416 23.0461 103.109 23.0461Z" :fill="color"/>
    <path d="M108.869 23.57C108.121 23.57 107.697 23.1751 107.697 22.4012V19.4993H108.291V22.3126C108.291 22.8284 108.468 23.038 109.031 23.038C109.439 23.038 109.778 22.8849 110.094 22.7237V19.4993H110.68V23.4975H110.171L110.117 23.1348H110.087C109.74 23.3846 109.331 23.57 108.869 23.57Z" :fill="color"/>
    <path d="M114.715 23.57C114.021 23.57 113.52 23.1992 113.52 22.4496C113.52 21.7483 113.952 21.3775 114.684 21.2888L115.717 21.1599V20.7407C115.717 20.12 115.455 19.9588 114.769 19.9588C114.399 19.9588 114.099 20.0152 113.86 20.0878V19.6444C114.191 19.4993 114.561 19.4268 115 19.4268C115.84 19.4268 116.311 19.7895 116.311 20.6037V23.4975H115.833L115.771 23.1509H115.733C115.555 23.3766 115.232 23.57 114.715 23.57ZM114.862 23.0864C115.216 23.0864 115.555 22.9655 115.717 22.7962V21.6193L114.838 21.7241C114.368 21.7725 114.137 21.974 114.137 22.4335C114.137 22.8688 114.422 23.0864 114.862 23.0864Z" :fill="color"/>
    <path d="M119.393 23.4975V19.4993H119.956V19.9507H119.994C120.21 19.6928 120.626 19.459 121.058 19.459C121.143 19.459 121.251 19.4671 121.289 19.4751V20.0313C121.189 20.0152 121.073 20.0072 121.012 20.0072C120.626 20.0072 120.256 20.1522 119.987 20.3538V23.4975H119.393Z" :fill="color"/>
    <path d="M125.605 23.57C124.403 23.57 123.794 22.8043 123.794 21.5548V21.442C123.794 20.0475 124.58 19.4268 125.458 19.4268C126.507 19.4268 127 20.0636 127 21.3049V21.6516H124.395C124.426 22.6511 124.88 23.038 125.697 23.038C126.206 23.038 126.545 22.9091 126.892 22.7398V23.1992C126.661 23.3685 126.222 23.57 125.605 23.57ZM125.443 19.9265C124.965 19.9265 124.457 20.2167 124.395 21.1599H126.383C126.383 20.2893 126.067 19.9265 125.443 19.9265Z" :fill="color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8497 9.22855L9.18195 10.6934L6.19813 8.74025V2.5444L10.3051 0L20.4955 5.77554V10.6934L17.8737 12.5148L20.4955 14.1285V18.9541L10.3051 25L0 19.2633L0 15.9676L10.3051 21.9942L17.8737 17.6769V15.9676V15.5312L12.4567 12.5148L17.8737 9.22855V7.41637L10.3051 2.88846L8.80633 3.97301V7.41637L11.8497 9.22855Z" :fill="color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.57402 4.16666V10.8746L12.9505 16.9663L10.674 18.5811L0 12.493L0 5.69338L2.57402 4.16666Z" :fill="color"/>
</svg>
</template>
<script lang="js">
export default {
  props: {
     name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    strokeWidth:{
        type: String,
      default: "2",
    }, secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
   customStyle:{default:{}},
   customClass:{type: String,default:''}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
