<template>
  <svg
    :class="['ic_' + name, customClass]"
    :style="customStyle"
    width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60" cy="60" r="60" :fill="color"/>
    <g clip-path="url(#clip0_201_3068)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M64.5492 49.6856L56.5458 54.0803L47.5944 48.2207V29.6332L59.9154 22L90.4865 39.3266V54.0803L82.6212 59.5443L90.4865 64.3856V78.8624L59.9154 97L29 79.7898V69.9028L59.9154 87.9825L82.6212 75.0306V69.9028V68.5936L66.3702 59.5443L82.6212 49.6856V44.2491L59.9154 30.6654L55.419 33.919V44.2491L64.5492 49.6856Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.7221 34.5V54.6239L67.8514 72.899L61.022 77.7432L29 59.479V39.0802L36.7221 34.5Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_201_3068">
    <rect width="61.5" height="75" fill="white" transform="translate(29 22)"/>
    </clipPath>
    </defs>
</svg>
</template>
<script lang="js">
export default {
  props: {
     name: {
      type: String,
      default:null,
    },
    color: {
      type: String,
      default: "#E3830F",
    },
    strokeWidth:{
        type: String,
      default: "2",
    }, secondColor: {
      type: String,
      default: "#E5E7EB",
    },
    sizeType:{
        type: String,
        default: '24',
    },
   customStyle:{default:{width:'25px',height:'25px'}},
   customClass:{type: String,default:''}
  },
  watch: {
  },
  data() {
    return {
      _root: this,
    };
  },
  methods: {},
  destroyed() {},
};
</script>
<style scoped>
@media only screen and (min-width: 320px) {
}
</style>
